/* src/components/AreaMeasurementDialog.css */
.area-measurement-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .area-measurement-dialog {
    background-color: #495057;
    border-radius: 8px;
    width: 60vw;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
  }
  
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .dialog-header h2 {
    margin: 0;
    color: #ffffff;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ffffff;
  }
  
  .dialog-body {
    margin-bottom: 20px;
  }
  
  .checkbox-group {
    margin-bottom: 15px;
  }
  
  .checkbox-group h3 {
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .checkbox-group label {
    display: flex; /* Use flexbox */
    align-items: center; /* Center align items */
    color: #ffffff;
    margin: 5px 0; /* Adjust margin as needed */
  }

  .checkbox-group input[type="radio"] {
    margin-right: 10px; /* Add space between radio button and label */
  }
  
  .radio-group {
    display: flex;
    gap: 20px; /* Adjusts the space between each radio button */
  }
  
  .warning-message {
    color: #ffc107;
    margin-top: 10px;
    font-style: italic;
  }
  
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .start-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  