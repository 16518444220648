/* src/ImagePopup.css */
.image-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.image-popup-content {
  position: relative;
  width: 80%;
  max-width: 740px;
  max-height: 80%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.5); /* Set background to semi-transparent */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-popup-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

figure {
  overflow: visible !important;
  
}

.image-popup-inner-image {
  transform: rotate(100deg);
}