/* src/Map.css */
.map-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  
  .area-display {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #495057;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    z-index: 2;
  }
  
  .popup-container {
    position: absolute;
    z-index: 2000; /* Adjust the value as needed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }