
.toolbar {
    margin-top: 60px;
    position: absolute;
    top: 10px;
    right: 10px; /* Position on the right side of the screen */
    width: 200px;
    max-height: 90%;
    min-height: 60%;
    z-index: 1000;
    overflow-y: auto;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  
  .toolbar .card-body {
    padding: 1rem;
    min-height: 10rem;
  }
  
  .toolbar .toggle-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .toolbar .toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .toolbar .switch {
    display: inline-block;
    width: 40px;
    height: 20px;
    position: relative;
  }
  
  .toolbar .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toolbar .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
  }
  
  .toolbar .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    top: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  .toolbar input:checked + .slider {
    background-color: #2196f3;
  }
  
  .toolbar input:checked + .slider:before {
    transform: translateX(20px);
  }
  
  .toolbar-toggle-button {
    margin-right: 10px;
    margin-top: 10px;
    z-index: 1;
    padding: 10px;
    background-color: #495057;
    color: white;
    border: 1px solid #ccc;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}
/* Add this to Toolbar.css */

.ratios-button {
  padding: 10px;
  background-color: #495057;
  color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.area-measurement-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #495057;
  color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.custom-tools-span {
  display: block;
  text-align: center;
  font-size: 1.05em; /* Adjust the font size as needed */
  margin-top: 15px; /* Adjust the margin as needed */
}


  