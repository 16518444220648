/* src/Menu.css */
.menu {
  margin-top: 60px;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px;
  max-height: 90%; /* Adjust this as needed */
  min-height: 60%; /* Adjust this as needed */
  z-index: 1000;
  overflow-y: auto;
  background-color: #f8f9fa; /* Override theme color */
  border: 1px solid #dee2e6; /* Customize border */
  border-radius: 4px; /* Rounded corners */
}

.menu .card-body {
  padding: 1rem;
  min-height: 30rem;
}

.menu .card-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.menu .list-group-item {
  cursor: pointer;
}

.menu .list-group-item:hover {
  background-color: #e9ecef; /* Custom hover effect */
}

/* Dark mode specific styles */
body.bg-dark .menu .form-control {
  background-color: #495057; /* Dark background for input */
  color: #fff !important; /* White text color for input */
  border: 1px solid #6c757d; /* Border color for input */
}

body.bg-dark .menu .form-control::placeholder {
  color: #adb5bd; /* Placeholder text color */
}

/* Toggle buttons styles */
.toggle-buttons {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-container span {
  flex: 1;
  text-align: left;
}

.switch {
  display: inline-block;
  width: 40px;
  height: 20px;
  position: relative;
  margin-left: 20px; /* Fixed space between label and toggle */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.menu-toggle-button {
  margin-left: 10px;
  margin-top: 10px;
  z-index: 1;
  padding: 10px;
  background-color: #495057; /* Dark background for input */
  color: white; /* Adjust text color to suit your design */
  border: 1px solid #ccc; /* Optional border */
  cursor: pointer;
  position: relative; /* Ensure it stays relative to menu */
  top: 10px;
  left: 10px;
}
