.ratios-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.ratios-dialog {
    background-color: #495057; /* Dark background color */
    border-radius: 8px;
    width: 60vw; /* 60% of the viewport width */
    height: 70vh; /* 70% of the viewport height */
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee2e6; /* Light border for contrast */
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.dialog-header h2 {
    margin: 0;
    color: #ffffff; /* Light text color for readability */
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ffffff; /* Close button color for visibility */
}

.dialog-body {
    max-height: calc(70vh - 150px); /* Height of the dialog minus header/footer space */
    overflow-y: auto;
}

.ratios-table {
    width: 100%;
    border-collapse: collapse; /* Collapse borders for a cleaner look */
    position: relative; /* Required for sticky positioning */
}

.ratios-table th,
.ratios-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6; /* Light border for table rows */
}

.ratios-table th {
    background-color: #6c757d; /* Slightly lighter background for table headers */
    color: #ffffff; /* Header text color for readability */
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick to the top of the table */
    z-index: 1; /* Ensure header is above other content */
}

.ratios-table tr:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Light hover effect for rows */
}

.dialog-footer {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Add some space between the buttons */
    margin-top: 20px;
}


.select-group-button {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
}

.select-group-button:disabled {
    background-color: #cccccc; /* Gray color when disabled */
    cursor: not-allowed;
}

.discard-button {
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
}

.ratios-table tr.selected {
    background-color: rgba(0, 123, 255, 0.3); /* Light blue background for selected row */
}

.ratios-table th,
.ratios-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
  width: 30%; /* Ensure both columns take up equal width */
}

.ratios-table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #6c757d;
  color: #ffffff;
}

.ratios-table th span {
  display: inline-block;
  min-width: 15px; /* Ensure space is reserved for the sorting icon */
}

