/* src/App.css */
.app {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.no-map {
  background: url('./images/background.jpeg') no-repeat center center fixed;
  background-size: cover;
}

.map-container {
  width: 100%;
  height: 100%;
}

.menu {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px;
  max-height: 300px; /* Adjust this as needed */
  z-index: 1000;
  overflow-y: auto;
}

.theme-toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.theme-toggle-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: inherit; /* Maintain text color based on theme */
}
